@import url('https://fonts.googleapis.com/css?family=Montserrat:500');

body{
  background: #FF8257;
  font-family: "Montserrat", sans-serif;
}
*{
  box-sizing: border-box;
}
main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
h1.header{
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 100px;
  letter-spacing: 20px;
  font-weight: 500;
  margin: 0px;
  animation: zoomIn 800ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  padding-left: 15px;
}
.img-wrapper{
  flex: 1 1 auto;
  width: 50vw;
  display: flex;
  justify-content: center;
  /* margin-top: 5vw; */
  margin-top: 5px;
}
.img-wrapper svg{
  width: 100%;
  height: 100%;
  animation: fadeInUpBig 400ms;
}
.subline{
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  padding: 8px;
  animation: fadeInUp 400ms;
  animation-delay: 200ms;
  opacity: 0;
  animation-fill-mode: forwards;
}
.subline:last-of-type{
  margin-top: 1vw;
  animation-delay: 300ms;
}
input.email{
  width: 60vw;
  max-width: 600px;
  height: 70px;
  font-size: 32px;
  margin-top: 3vw;
  background: rgba(255,255,255,.1);
  border: 4px solid #ffffff;
  border-radius: 10px;
  /* padding-left: 1vw; */
  text-align: center;
  color: #fff;
}
input.email::placeholder{
  color: rgba(255,255,255,.5);
}
input.email:focus{
  outline: none;
  background: rgba(255,255,255,.2);
  /* color: #FF8257; */
}
/* input.email:focus::placeholder{
  color: rgba(255, 130, 87, 0.32);
} */

form.form-wrapper{
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  animation: fadeInUpBig 400ms;
}
.button-wrapper{
  display: flex;
  justify-content: center;
}
button.submit-button{
  background: #fff;
  border: none;
  margin-top: 2vw;
  height: 50px;
  color: #FF8257;
  font-weight: 500;
  font-size: 26px;
  border-radius: 6px;
  padding: 5px 20px;;
}
button.submit-button:hover{
  background: rgba(255,255,255,.9);
}
button.submit-button:disabled{
  background: rgba(255,255,255,.8)
}
.success{
  display: flex;
  flex-direction: column;
  color: #ffffff;
  flex: 1 1 auto;
}
.success p{
  text-align: center;
}
.success h2{
  text-align: center;
  font-size: 36px;
  margin-top: 0px;
}
.success p a{
  color: #ffffff;
  text-decoration: underline;
}
.error{
  text-align: center;
  font-style: italic;
  margin-top: 1vw;
  color: #ffffff;
  max-width: 60vw;
}
@media (max-width: 700px) {
  h1.header{
    font-size: 80px;
    padding-left: 20px;
  }
  .img-wrapper{
    width: 60vw;
    /* margin-top: 10vw; */
  }
  input.email{
    width: 90vw;
    margin-top: 4.5vw;
  }
  button.submit-button{
    margin-top: 5vw;
  }
  .subline:last-of-type{
    /* margin-top: 2vw; */
  }
  .error{
    max-width: 90vw;
  }
}
@media (max-width: 500px) {
  .img-wrapper{
    width: 75vw;
  }
  input.email{
    width: 90vw;
    font-size: 30px;
  }
  .subline{
    font-size: 20px;
  }
  .error{
    max-width: 90vw;
  }
}
@media (max-width: 400px) {
  .img-wrapper{
    width: 75vw;
  }
  input.email{
    width: 90vw;
    height: 60px;
    font-size: 24px;
    margin-top: 0px;
  }
  .subline{
    font-size: 20px;
  }
  button.submit-button{
    font-size: 24px;
  }
  h1.header{
    font-size: 60px;
  }
  .error{
    max-width: 90vw;
  }
}
@media (min-width: 1200px) {
  .img-wrapper{
    width: 40vw;
  }
}
.title-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
}

@keyframes zoomIn {
  from{
    transform: translateY(-3vh) scale(.8);
    opacity: 0;
  }
  to{
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from{
    opacity: 0;
    transform: translateY(2vw);
  }
  to{
    opacity: 1;
  }
}

@keyframes fadeInUpBig {
  from{
    opacity: 0;
    transform: translateY(4vw);
  }
  to{
    opacity: 1;
  }
}
